Vue.component('userSectionComponent',{
    data:function(){
        return {
            baget_in_car:0,
            show_modal:true
        }
    },
    mounted: function() {
        $('#changePasswordAction').modal();
    },
    computed:{
        session:function(){
            return this.$store.getters.getSession;
        },
        salesman:function(){
            return this.$store.getters.getSalesMan;
        }
    },
    methods:{
        logout:async function(){
            await this.$store.dispatch('logout');
            document.cookie = 'connect.ecommerceMarken.sid' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            this.$router.push('/home');
        },
        openModalLogin:function () {
            $('#loginModal').modal();
            $('#loginModal').modal('open');
        },
        openModalRegister:function () {
            $('.modal').modal('close');
            $('#registerModal').modal();
            $('#registerModal').modal('open');
        },
        openModalReset:function(){
           // $('.modal').modal('close');
            //$('#resetModal').modal();
            $('#resetModal').modal('open');
        },
        openModalChangePassword:function () {
            $('#changePasswordAction').modal();
            $('#changePasswordAction').modal('open');
        },
        openModalCustomer:()=>{
            $('.modal').modal('close');
            $('#registerCustomerModal').modal();
            $('#registerCustomerModal').modal('open');
        },
    },
    template:`<div class="card-panel user-section row">
                <template v-if="session==null">
                        <div class="row">
                            <button class="modal-trigger waves-light btn col s12" href="#loginModal" @click="openModalLogin">
                                {{tr('Login')}}
                            </button>
                            <a class="modal-trigger waves-light btn col s12" href="#resetModal">
                                {{tr('Lost Password')}}       
                            </a>
                        </div>
                </template>
                <template v-else>
                    <div class="row">
                        <div class="col 12 m3 s12">
                            <img src="../static/ecommerce/assets/img/user.jpg" width="40" alt="">
                        </div>
                        <div class="col 12 m8 s12 account-info">
                            <h5 class="white-text">{{tr('My Account')}}</h5>
                            <a class='dropdown-button' href='#' data-activates='dropdown1'><i class="fas fa-angle-down"></i></a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col 12 m12 s12" vi-f="session.fields">
                            <h5 class="white-text">{{session.fields.Name}} {{session.fields.LastName}}</h5>
                            <img :src="'../ecommerce/api/myavatar/'+session.fields.Code" alt="" width="147" height="98" class="responsive-img">
                            <p v-if= "salesman" style="color: #FFFFFF !important; font-size: 12px !important">{{tr("Your account executive is")}}:<br>
                                {{salesman.Name}} {{salesman.LastName}} <br>
                                {{salesman.Phone}}<br>
                                {{salesman.Email}}
                            </p>
                        </div>
                        <a class="btn checkout-btn col m12 s12 modal-trigger"  @click="openModalChangePassword">{{tr('Change Password')}}</a>
                        <a class="btn checkout-btn col m12 s12" @click="logout">{{tr('Log Out')}}</a>
                       
                    </div>
                </template>
            </div>`,
});
